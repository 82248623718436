import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix"
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
  useRouteError,
  json,
} from "@remix-run/react"
import tailwindStyleSheetUrl from "~/styles/tailwind.css?url"

export const links = () => {
  return [
    { rel: "preload", href: tailwindStyleSheetUrl, as: "style" },
    { rel: "stylesheet", href: tailwindStyleSheetUrl },
    {
      rel: "preload",
      href: "https://fonts.googleapis.com/css2?family=Quando&display=swap",
      as: "style",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Quando&display=swap",
      crossOrigin: "anonymous",
    },
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
      crossOrigin: "anonymous",
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/apple-touch-icon.png?v=3",
    },
    { rel: "icon", type: "image/png", href: "/favicon-32x32.png?v=3" },
    { rel: "icon", type: "image/png", href: "/favicon-16x16.png?v=3" },
    { rel: "manifest", href: "/site.webmanifest?v=3" },
    { rel: "mask-icon", href: "/safari-pinned-tab.svg?v=3", color: "#5bbad5" },
    { rel: "shortcut icon", href: "/favicon.ico?v=3" },
  ]
}

// Load the GA tracking id from the .env
export const loader = async () => {
  return json({
    GOOGLE_MEASUREMENT_ID: process.env.GOOGLE_MEASUREMENT_ID,
    APP_ENV: process.env.APP_ENV,
    RELEASE: process.env.RELEASE,
    SENTRY_DSN: process.env.SENTRY_DSN,
    SENTRY_TRACE_RATE: process.env.SENTRY_TRACE_RATE,
    SENTRY_REPLAY_SESSION_RATE: process.env.SENTRY_REPLAY_SESSION_RATE,
    SENTRY_REPLAY_ERROR_RATE: process.env.SENTRY_REPLAY_ERROR_RATE,
  })
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)

  return (
    <>
      <div className="prose mx-auto flex min-h-svh max-w-md items-center">
        <p>{error?.message ? error.message : ""}</p>
      </div>
    </>
  )
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData("root")

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <Meta />
        <Links />
      </head>
      <body>
        {!data?.GOOGLE_MEASUREMENT_ID ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${data.GOOGLE_MEASUREMENT_ID}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${data.GOOGLE_MEASUREMENT_ID}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
            <noscript>
              <iframe
                title="GA"
                src={`https://www.googletagmanager.com/ns.html?id=${data.GOOGLE_MEASUREMENT_ID}`}
                height="0"
                width="0"
                className="invisible hidden"
              ></iframe>
            </noscript>
          </>
        )}
        {children}
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify({
              APP_ENV: data.APP_ENV,
              RELEASE: data.RELEASE,
              SENTRY_DSN: data.SENTRY_DSN,
              SENTRY_TRACE_RATE: parseFloat(data.SENTRY_TRACE_RATE),
              SENTRY_REPLAY_SESSION_RATE: parseFloat(
                data.SENTRY_REPLAY_SESSION_RATE,
              ),
              SENTRY_REPLAY_ERROR_RATE: parseFloat(
                data.SENTRY_REPLAY_ERROR_RATE,
              ),
            })}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  )
}

function App() {
  return <Outlet />
}

export default withSentry(App)